<template>
    <div>
      <b-container fluid>
          <b-row>
            <b-col lg="12" sm="12">
              <iq-card>
                <template>
                  <b-overlay :show="loading">
                      <b-col lg="12">
                          <b-row>
                              <div class="card card w-100 text-center">
                                  <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                                      <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">    {{ $t('globalTrans.company_name') }}</h5>
                                  </div>
                              </div>
                          </b-row>
                          <b-row>
                              <b-col lg="12" sm="12" class="p-0">
                                  <b-table-simple bordered striped>
                                  <b-thead>
                                      <b-tr>
                                          <b-th style="" scope="col">{{ $t('globalTrans.company_name') }}</b-th>
                                          <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.company_name_bn : profile.company_name }}</b-td>
                                      </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                      <b-tr>
                                          <b-th style="" class="w-50" scope="row">{{ $t('globalTrans.company_mobile') }}</b-th>
                                          <b-td style="" class="w-50">{{ profile.company_mobile ? ($i18n.locale == 'bn' ? '০': '0') + $n(profile.company_mobile, { useGrouping: false }) : '' }}</b-td>
                                      </b-tr>
                                      <b-tr>
                                          <b-th style="" scope="row">{{ $t('dae_config.tin') }}</b-th>
                                          <b-td style="">{{ profile.company_tin ? $n(profile.company_tin, { useGrouping: false }) : '' }}</b-td>
                                      </b-tr>
                                      <b-tr>
                                          <b-th style="" scope="row">{{ $t('dae_config.vat_regis') }}</b-th>
                                          <b-td style="">{{ profile.vat_registration_no ? $n(profile.vat_registration_no, { useGrouping: false }) : '' }}</b-td>
                                      </b-tr>
                                      <b-tr>
                                          <b-th style="" scope="row">{{ $t('dae_config.trade_regis') }}</b-th>
                                          <b-td style="">{{ profile.trade_registration_no ? $n(profile.trade_registration_no, { useGrouping: false }) : '' }} </b-td>
                                      </b-tr>
                                      </b-tbody>
                                  </b-table-simple>
                              </b-col>
                          </b-row>

                          <b-row>
                              <div class="card card w-100 text-center">
                                  <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                                      <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">    {{ $t('externalTraining.present_address') }}</h5>
                                  </div>
                              </div>
                          </b-row>
                          <b-row>
                              <b-col lg="12" sm="12" class="p-0">
                                  <b-table-simple bordered striped>
                                  <b-thead>
                                      <b-tr>
                                          <b-th style="" scope="col">{{ $t('org_pro.area_type') }}</b-th>
                                          <b-th style="" scope="col">{{ ($i18n.locale=='bn') ? profile.area_name_bn : profile.area_name }}</b-th>
                                      </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                      <b-tr>
                                          <b-th style="" class="w-50" scope="row">{{ $t('org_pro_division.division') }}</b-th>
                                          <b-td style="" class="w-50">{{ ($i18n.locale=='bn') ? profile.division_name_bn : profile.division_name }}</b-td>
                                      </b-tr>
                                      <b-tr>
                                          <b-th style="" scope="row">{{ $t('org_pro_district.district') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.district_name_bn : profile.district_name }}</b-td>
                                      </b-tr>
                                      <b-tr v-if="profile.area_type_id !== 1">
                                          <b-th style="" scope="row">{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.upazilla_name_bn : profile.upazilla_name }}</b-td>
                                      </b-tr>
                                      <b-tr v-if="profile.area_type_id === 3">
                                          <b-th style="" scope="row">{{ $t('org_pro_union.union') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.union_name_bn : profile.union_name }}</b-td>
                                      </b-tr>
                                      <b-tr v-if="profile.area_type_id === 1">
                                          <b-th style="" scope="row">{{ $t('org_pro.city_corporation') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.cityCorporation_name_bn : profile.cityCorporation_name }}</b-td>
                                      </b-tr>
                                      <b-tr v-if="profile.area_type_id === 2">
                                          <b-th style="" scope="row">{{ $t('org_pro.pauroshoba') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_pauroshoba_name_bn : profile.per_pauroshoba_name }}</b-td>
                                      </b-tr>
                                      <b-tr v-if="profile.address_en">
                                          <b-th style="" scope="row">{{ $t('globalTrans.office_address') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.address_bn : profile.address_en }}</b-td>
                                      </b-tr>
                                      </b-tbody>
                                  </b-table-simple>
                              </b-col>
                          </b-row>
                      <b-row>
                          <div class="card card w-100 text-center">
                          <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">   {{ $t('externalTraining.parmanent_address') }}</h5>
                          </div>
                          </div>
                          </b-row>
                          <b-row>
                              <b-col lg="12" sm="12" class="p-0">
                                  <b-table-simple bordered striped>
                                  <b-thead>
                                      <b-tr>
                                          <b-th style="" scope="col">{{ $t('org_pro.area_type') }}</b-th>
                                          <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.per_area_name_bn : profile.per_area_name }}</b-td>
                                      </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                      <b-tr>
                                          <b-th style="" class="w-50" scope="row">{{ $t('org_pro_division.division') }}</b-th>
                                          <b-td style="" class="w-50">{{ ($i18n.locale=='bn') ? profile.per_division_name_bn : profile.per_division_name }}</b-td>
                                      </b-tr>
                                      <b-tr>
                                          <b-th style="" scope="row">{{ $t('org_pro_district.district') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_district_name_bn : profile.per_district_name }}</b-td>
                                      </b-tr>
                                      <b-tr v-if="profile.per_area_type_id !== 1">
                                          <b-th style="" scope="row">{{ $t('org_pro_upazilla.upazilla')}} </b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_upazilla_name_bn : profile.per_upazilla_name }}</b-td>
                                      </b-tr>
                                      <b-tr v-if="profile.per_area_type_id == 3">
                                          <b-th style="" scope="row">{{ $t('org_pro_union.union') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_union_name_bn : profile.per_union_name }}</b-td>
                                      </b-tr>
                                      <b-tr v-if="profile.per_area_type_id == 1">
                                          <b-th style="" scope="row">{{ $t('org_pro.city_corporation') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_cityCorporation_name_bn : profile.per_cityCorporation_name }}</b-td>
                                      </b-tr>
                                      <b-tr v-if="profile.per_area_type_id == 2">
                                          <b-th style="" scope="row">{{ $t('org_pro.pauroshoba') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_pauroshoba_name_bn : profile.per_pauroshoba_name }}</b-td>
                                      </b-tr>
                                      <b-tr v-if="profile.per_address_en">
                                          <b-th style="" scope="row">{{ $t('globalTrans.office_address') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_address_bn : profile.per_address_en }}</b-td>
                                      </b-tr>
                                      </b-tbody>
                                  </b-table-simple>
                              </b-col>
                          </b-row>

                          <b-row>
                              <div class="card card w-100 text-center">
                              <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                              <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;"> {{ $t('externalTraining.profile_info')+ ' ' + this.$t('globalTrans.details') }}</h5>
                              </div>
                              </div>
                          </b-row>
                          <b-row>
                              <b-col lg="12" sm="12" class="p-0">
                                  <b-table-simple bordered striped>
                                  <b-tbody>
                                      <b-tr>
                                          <b-th style="width:25%" colspan="2" rowspan="4"><div class="text-center"><img :src="incentiveGrantServiceBaseUrl + 'storage/' + profile.photo" style="width: 130px; height: 120px; border-radius: 0px !important;" class="center"></div></b-th>
                                      </b-tr>
                                      <!-- </b-thead>
                                      <b-tbody> -->
                                      <b-tr>
                                          <b-th style="">{{ $t('user.namel') }}</b-th>
                                          <b-td style="">{{ ($i18n.locale=='bn') ? profile.name_bn : profile.name }}</b-td>
                                      </b-tr>
                                      <b-tr>
                                          <b-th style="">{{ $t('globalTrans.mobile') }}</b-th>
                                          <b-td style="">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.mobile_no, { useGrouping: false }) }}</b-td>
                                      </b-tr>
                                      <b-tr>
                                          <b-th style=" " scope="row">{{ $t('externalTraining.email') }}</b-th>
                                          <b-td style=" ">{{ ($i18n.locale=='bn') ? profile.email : profile.email }}</b-td>
                                      </b-tr>
                                  </b-tbody>
                                  </b-table-simple>
                              </b-col>
                          </b-row>
                    </b-col>
                  </b-overlay>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </b-container>
    </div>
    </template>
    <script>
    import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
    import { profileInfoShow } from '../api/routes'
    export default {
        components: {
            // ListReportHead
        },
      created () {
        this.loadData()
      },
      data () {
        return {
          load: false,
          incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
          profile: {
                name: '',
                name_bn: '',
                email: '',
                nid: '',
                mobile: '',
                gender: '',
                status: 1
            }
        }
      },
      computed: {
          loading () {
              return this.$store.state.commonObj.loading
          },
        fiscalYearList: function () {
            return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        }
    },
      methods: {
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const params = {
              user_id: this.$store.state.Auth.authUser.id
            }
            RestApi.getData(incentiveGrantServiceBaseUrl, profileInfoShow, params).then(response => {
                if (response.success) {
                    this.profile = this.getCustomDataList(response.data)
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                }
            })
        },
        getCustomDataList (item) {
                const areaObj = this.$store.state.commonObj.wardTypeList.find(area => area.id === parseInt(item.area_type_id))
                if (typeof areaObj !== 'undefined') {
                  item.area_name = areaObj.name
                  item.area_name_bn = areaObj.name_bn
                } else {
                  item.area_name = ''
                  item.area_name_bn = ''
                }
                const perareaObj = this.$store.state.commonObj.wardTypeList.find(area => area.id === parseInt(item.per_area_type_id))
                if (typeof perareaObj !== 'undefined') {
                  item.per_area_name = perareaObj.name
                  item.per_area_name_bn = perareaObj.name_bn
                } else {
                  item.per_area_name = ''
                  item.per_area_name_bn = ''
                }
                const divisionObj = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(division => division.value === parseInt(item.division_id))
                if (typeof divisionObj !== 'undefined') {
                  item.division_name = divisionObj.text_en
                  item.division_name_bn = divisionObj.text_bn
                } else {
                  item.division_name = ''
                  item.division_name_bn = ''
                }
                const perdivisionObj = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(division => division.value === parseInt(item.per_division_id))
                if (typeof perdivisionObj !== 'undefined') {
                  item.per_division_name = perdivisionObj.text_en
                  item.per_division_name_bn = perdivisionObj.text_bn
                } else {
                  item.per_division_name = ''
                  item.per_division_name_bn = ''
                }
                const districtObj = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(district => district.value === parseInt(item.district_id))
                if (typeof divisionObj !== 'undefined') {
                  item.district_name = districtObj.text_en
                  item.district_name_bn = districtObj.text_bn
                } else {
                  item.district_name = ''
                  item.district_name_bn = ''
                }
                const perdistrictObj = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(district => district.value === parseInt(item.per_district_id))
                if (typeof perdivisionObj !== 'undefined') {
                  item.per_district_name = perdistrictObj.text_en
                  item.per_district_name_bn = perdistrictObj.text_bn
                } else {
                  item.per_district_name = ''
                  item.per_district_name_bn = ''
                }
                const upazillaObj = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(upazilla => upazilla.value === parseInt(item.upazilla_id))
                if (typeof upazillaObj !== 'undefined') {
                  item.upazilla_name = upazillaObj.text_en
                  item.upazilla_name_bn = upazillaObj.text_bn
                } else {
                  item.upazilla_name = ''
                  item.upazilla_name_bn = ''
                }
                const perupazillaObj = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(perupazilla => perupazilla.value === parseInt(item.per_upazilla_id))
                if (typeof perupazillaObj !== 'undefined') {
                  item.per_upazilla_name = perupazillaObj.text_en
                  item.per_upazilla_name_bn = perupazillaObj.text_bn
                } else {
                  item.per_upazilla_name = ''
                  item.per_upazilla_name_bn = ''
                }
                const cityCorporationObj = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.find(cityCorporation => cityCorporation.value === parseInt(item.city_corporation_id))
                if (typeof cityCorporationObj !== 'undefined') {
                  item.cityCorporation_name = cityCorporationObj.text_en
                  item.cityCorporation_name_bn = cityCorporationObj.text_bn
                } else {
                  item.cityCorporation_name = ''
                  item.cityCorporation_name_bn = ''
                }
                const percityCorporationObj = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.find(cityCorporation => cityCorporation.value === parseInt(item.per_city_corporation_id))
                if (typeof percityCorporationObj !== 'undefined') {
                  item.per_cityCorporation_name = percityCorporationObj.text_en
                  item.per_cityCorporation_name_bn = percityCorporationObj.text_bn
                } else {
                  item.per_cityCorporation_name = ''
                  item.per_cityCorporation_name_bn = ''
                }
                const unionObj = this.$store.state.ExternalUserIrrigation.commonObj.unionList.find(union => union.value === parseInt(item.union_id))
                if (typeof unionObj !== 'undefined') {
                  item.union_name = unionObj.text_en
                  item.union_name_bn = unionObj.text_bn
                } else {
                  item.union_name = ''
                  item.union_name_bn = ''
                }
                const perunionObj = this.$store.state.ExternalUserIrrigation.commonObj.unionList.find(union => union.value === parseInt(item.per_union_id))
                if (typeof perunionObj !== 'undefined') {
                  item.per_union_name = perunionObj.text_en
                  item.per_union_name_bn = perunionObj.text_bn
                } else {
                  item.per_union_name = ''
                  item.per_union_name_bn = ''
                }
                const wardObj = this.$store.state.ExternalUserIrrigation.commonObj.wardList.find(ward => ward.value === parseInt(item.ward_id))
                if (typeof wardObj !== 'undefined') {
                  item.ward_name = wardObj.text_en
                  item.ward_name_bn = wardObj.text_bn
                } else {
                  item.ward_name = ''
                  item.ward_name_bn = ''
                }
                const perwardObj = this.$store.state.ExternalUserIrrigation.commonObj.wardList.find(ward => ward.value === parseInt(item.per_ward_id))
                if (typeof perwardObj !== 'undefined') {
                  item.per_ward_name = perwardObj.text_en
                  item.per_ward_name_bn = perwardObj.text_bn
                } else {
                  item.per_ward_name = ''
                  item.per_ward_name_bn = ''
                }
                const pauroshobaObj = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.find(per => per.value === parseInt(item.pauroshoba_id))
                if (typeof pauroshobaObj !== 'undefined') {
                  item.pauroshoba_name = pauroshobaObj.text_en
                  item.pauroshoba_name_bn = pauroshobaObj.text_bn
                } else {
                  item.pauroshoba_name = ''
                  item.pauroshoba_name_bn = ''
                }
                const perpauroshobaObj = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.find(per => per.value === parseInt(item.per_pauroshoba_id))
                if (typeof perpauroshobaObj !== 'undefined') {
                  item.per_pauroshoba_name = perpauroshobaObj.text_en
                  item.per_pauroshoba_name_bn = perpauroshobaObj.text_bn
                } else {
                  item.per_pauroshoba_name = ''
                  item.per_pauroshoba_name_bn = ''
                }
              return item
            }
      }
    }
    </script>
    <style>
      .hidden_header {
        display: none
      }
      .card-border {
        border: 1px solid #b9bdc1;
        box-shadow: 1px 1px 6px -1px grey;
        background-color: #dee2e6;
      }
      [dir=ltr][mode=light] .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 10px;
      }
      .report-name{
        font-weight: bold !important;
        text-transform: uppercase;
      }
      .my-btn{
        padding: 2px !important;
      }
    </style>
